import { useMemo, useRef, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import Slider, { Settings } from 'react-slick'
import { InfoCard, StatusTagV2 } from 'component-library'
import { useSetSlideLinkTabIndex } from 'shared-utilities'
import BuildWithPLSection from '../../components/BuildWithPennylaneSection/BuildWithPennylane'
import CardLink from '../../components/CardLink/CardLink'
import DemosFeaturedCarousel from '../../components/DemosFeaturedCarousel/DemosFeaturedCarousel'
import DemoSearchAndTags from '../../components/DemoSearchAndTags/DemoSearchAndTags'
import HubTopSection from '../../components/Hubs/TopSection/TopSection'
import LinkComponent from '../../components/LinkComponent/LinkComponent'
import LoaderSpinner from '../../components/LoaderSpinner/LoaderSpinner'
import Seo from '../../components/seo'
import SpotlightSection from '../../components/SpotlightSection/SpotlightSection'
import { IStandaloneChallenge } from '../../types'
import { demosCategories } from '../../../content/demos/demonstrations_categories'
import superhub from '../../content/qml/hubs/superhub'
import videos from '../../content/qml/videos'
import { spotlights } from 'shared-content'
import '../../scss/pages/superhub.scss'

const {
  meta_title,
  meta_description,
  meta_image,
  top_section,
  see_all_content,
  featured_demos,
  all_content,
  get_started,
} = superhub

const spotlight = spotlights.learn_page

export const GET_STANDALONE_CHALLENGES = gql(`
  query getStandaloneChallenges {
    standaloneChallenges {
      challenge {
        slug
        summary
        title
      }
      createdAt
      isAttempted
      isCompleted
      difficulty
      iconUrl
      primaryCategory
    }
  }
`)

const sortChallenges = (challenges: IStandaloneChallenge[]) => {
  if (challenges?.length <= 0) return
  return challenges?.sort(
    (a: IStandaloneChallenge, b: IStandaloneChallenge) =>
      -a.createdAt.localeCompare(b.createdAt)
  )
}

export default function Superhub() {
  const { isAuthenticated } = useAuth0()
  const formattedVideos = videos.cards.map((el) => {
    return {
      link: `https://youtube.com/watch?v=${el.videoId}`,
      title: el.title,
      image: `https://img.youtube.com/vi/${el.videoId}/mqdefault.jpg`,
    }
  })

  const { loading, data, error } = useQuery(GET_STANDALONE_CHALLENGES)

  const challenges = useMemo(() => {
    const challengesData = data?.standaloneChallenges || []
    const sortArray = [...challengesData]

    // Returning the latest challenges first
    if (challengesData?.length) return sortChallenges(sortArray)
  }, [data, loading, error, isAuthenticated])

  return (
    <div
      className="bg-no-repeat mt-[50px]"
      style={{
        backgroundImage: `url(https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/bg-curtains.svg)`,
      }}
    >
      <div className="Superhub">
        <section className="px-10 md:px-11 lg:px-0">
          <HubTopSection
            title={top_section.title}
            sub_title_1={top_section.sub_title_1}
            sub_title_2={top_section.sub_title_2}
            link_cards={top_section.link_cards}
            sub_link={
              <LinkComponent
                href={see_all_content.link}
                className="Superhub__link Superhub__link--mobile"
              >
                <span>{see_all_content.text}</span>{' '}
                <i className="bx bx-chevron-right"></i>
              </LinkComponent>
            }
          />

          <LinkComponent
            href={see_all_content.link}
            className="Superhub__link Superhub__link--desktop"
          >
            <span>{see_all_content.text}</span>{' '}
            <i className="bx bx-chevron-right"></i>
          </LinkComponent>

          {!spotlight.hidden && (
            <SpotlightSection announcements={spotlight.announcements} />
          )}
        </section>

        <section className="Superhub__FeaturedDemos">
          <DemosFeaturedCarousel title={featured_demos.title} />
          <p className="text-xl text-[#232323] font-roboto font-light mb-6 px-10">
            {featured_demos.description}
          </p>
          <DemoSearchAndTags
            description={featured_demos.tags_description}
            demosCategories={demosCategories}
          />
        </section>

        {challenges?.length === 0 && loading && (
          <div className="flex justify-center">
            <LoaderSpinner />
          </div>
        )}

        {loading && (
          <div className="py-10">
            <LoaderSpinner />
          </div>
        )}
        {!loading && challenges && challenges.length > 0 && (
          <QMLSlider
            title="Coding challenges"
            seeAllLink="/challenges"
            cards={challenges.map((challenge) => {
              const status = challenge.isCompleted
                ? 'completed'
                : challenge.isAttempted && !challenge.isCompleted
                ? 'in-progress'
                : null

              return {
                title: challenge.challenge.title,
                image: challenge.iconUrl || '',
                link: `/challenges/${challenge.challenge.slug}`,
                challengeDifficulty: challenge.difficulty,
                challengeStatus: status,
              }
            })}
          />
        )}

        <QMLSlider
          title="Videos"
          seeAllLink="/qml/videos/"
          cards={formattedVideos}
        />

        <section className="Superhub__AllContent" id="all-content">
          <h2 className="AllContent__Title">{all_content.title}</h2>
          <div className="AllContent__Cards">
            {all_content.cards.map((card, index) => {
              return (
                <div
                  className="AllContent__Card"
                  key={`${card.title}-${index}`}
                >
                  <CardLink {...card} />
                </div>
              )
            })}
          </div>
        </section>

        <BuildWithPLSection
          title={get_started.title}
          buttons={get_started.buttons}
        />
      </div>
    </div>
  )
}

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}

type QMLSliderCard = {
  title: string
  image: string
  link: string
  challengeDifficulty?: 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED' | null
  challengeStatus?: 'completed' | 'in-progress' | null
}

const QMLSlider = ({
  title,
  seeAllLink,
  cards,
}: {
  title: string
  seeAllLink: string
  cards: QMLSliderCard[]
}) => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: true,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          slidesToShow: 2,
          swipe: true,
          infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          dots: false,
          slidesToShow: 1,
          swipe: true,
          infinite: true,
        },
      },
    ],
  }

  return (
    <div className="QMLSlider">
      <div className="container">
        <div className="flex justify-between">
          <h2 className="text-left text-h3">{title}</h2>
          <LinkComponent href={seeAllLink} className="QMLSlider__link-desktop">
            See all ({cards.length})
          </LinkComponent>
        </div>
        <Slider {...settings}>
          {cards.slice(0, 10).map((card, i) => (
            <div key={`QMLSliderCard-${card.title}-${i}`} className="h-full">
              <QMLSliderCard {...card} />
            </div>
          ))}
        </Slider>
      </div>
      <LinkComponent href={seeAllLink} className="QMLSlider__link-mobile">
        See all ({cards.length})
      </LinkComponent>
    </div>
  )
}

const QMLSliderCard = ({
  title,
  image,
  link,
  challengeDifficulty,
  challengeStatus,
}: QMLSliderCard) => {
  const cardRef = useRef(null)
  const [isVisible, toggleIsVisible] = useState<boolean>(true)
  useSetSlideLinkTabIndex(cardRef, toggleIsVisible, true)
  const tabIndex = isVisible ? 0 : -1

  return (
    <LinkComponent href={link} tabIndex={tabIndex}>
      <InfoCard
        label={title}
        image={image}
        tagsTopRight={
          <>
            {challengeStatus && (
              <StatusTagV2 status={challengeStatus} tabIndex={tabIndex} />
            )}
            {challengeDifficulty && (
              <StatusTagV2 status={challengeDifficulty} tabIndex={tabIndex} />
            )}
          </>
        }
      />
    </LinkComponent>
  )
}
