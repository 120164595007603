import { useRef, useState } from 'react'
import { useSetSlideLinkTabIndex } from 'shared-utilities'
import LinkComponent from '../LinkComponent/LinkComponent'
import TagComponent from '../TagComponent/TagComponent'
import './style.scss'

const DemosFeaturedCard = ({
  image,
  title,
  categories,
  slug,
}: {
  image?: string
  title: string
  categories: string[]
  slug: string
}) => {
  const cardRef = useRef(null)
  const [isVisible, toggleIsVisible] = useState<boolean>(true)
  useSetSlideLinkTabIndex(cardRef, toggleIsVisible, true)

  return (
    <div ref={cardRef}>
      <LinkComponent href={`/qml/demos/${slug}`} tabIndex={isVisible ? 0 : -1}>
        <div
          className="DemosFeaturedCard"
          style={{ backgroundImage: `url(${image}) ` }}
        >
          <div className="DemosFeaturedCard__gradient"></div>
          <div className="DemosFeaturedCard__content">
            <div className="DemosFeaturedCard__tags">
              {categories.map((category) => (
                <TagComponent key={category} text={category} />
              ))}
            </div>
            <h3>{title}</h3>
          </div>
        </div>
      </LinkComponent>
    </div>
  )
}

export default DemosFeaturedCard
