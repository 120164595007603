import Slider, { Settings } from 'react-slick'
import DemosFeaturedCard from '../DemosFeaturedCard/DemosFeaturedCard'
import { graphql, useStaticQuery } from 'gatsby'
import { IDemosMarkdownRemarkQuery } from '../DemoCategoyList/DemoCategoryList'
import { withPrefix } from 'gatsby'
import './DemosFeaturedCarousel.scss'
import { getImageByTypeFromMetaData } from '../../utils/demo_helpers'

interface IDemosFeaturedCarouselProps {
  title: string
  className?: string
}

const DemosFeaturedCarousel = ({
  title,
  className = '',
}: IDemosFeaturedCarouselProps) => {
  const slickSettings: Settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  }

  const { allMarkdownRemark }: IDemosMarkdownRemarkQuery =
    useStaticQuery(demosFeaturedQuery)
  const demos = allMarkdownRemark.nodes

  const getCardImage = (previewImages: { uri: string; type: string }[]) => {
    const image = getImageByTypeFromMetaData(previewImages, 'large_thumbnail')
    return withPrefix(image)
  }

  const carouselDemos = demos.map((demo) => (
    <div key={demo.frontmatter.slug} className="DemosFeaturedCarousel__card">
      <DemosFeaturedCard
        slug={demo.frontmatter.slug}
        image={getCardImage(demo.frontmatter.previewImages)}
        title={demo.frontmatter.title}
        categories={demo.frontmatter.categories}
      />
    </div>
  ))

  return carouselDemos.length ? (
    <section className={`DemosFeaturedCarousel ${className}`}>
      <h2 className="DemosFeaturedCarousel__title">{title}</h2>
      <div className="my-10">
        <Slider {...slickSettings}>{carouselDemos}</Slider>
      </div>
    </section>
  ) : null
}

export default DemosFeaturedCarousel

export const demosFeaturedQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/demos/" }
        frontmatter: {
          previewImages: { elemMatch: { type: { regex: "/large_thumbnail/" } } }
        }
      }
      sort: { fields: frontmatter___date_of_publication, order: DESC }
      limit: 5
    ) {
      nodes {
        frontmatter {
          categories
          title
          slug
          meta_description
          previewImages {
            type
            uri
          }
        }
      }
    }
  }
`
