import LinkComponent from '../LinkComponent/LinkComponent'
import './TagComponent.scss'

export interface ITagComponent {
  link?: string
  text: string
  className?: string
  icon?: string
  onClick?: () => void
}

const TagComponent = ({
  link,
  text,
  className,
  icon,
  onClick,
}: ITagComponent) => {
  const tagContent = (
    <>
      {icon && <i className={`bx ${icon} mr-2`}></i>}
      {text}
    </>
  )

  const customTagClass = `TagComponent ${className ? className : ''}`

  return link ? (
    <LinkComponent className={customTagClass} href={link}>
      {tagContent}
    </LinkComponent>
  ) : (
    <span className={customTagClass} onClick={onClick}>
      {tagContent}
    </span>
  )
}

export default TagComponent
