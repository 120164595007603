import { ChangeEvent } from 'react'
import './style.scss'

const SearchInput = ({
  onInputChange,
  onClear,
  value,
  placeholder = 'Search',
}: {
  onInputChange: (updatedValue: string) => void
  onClear: () => void
  value: string
  placeholder?: string
}) => {
  return (
    <div className="SearchBar">
      <input
        data-testid="search-input"
        className="SearchBar__input"
        placeholder={placeholder}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onInputChange(e.target.value)
        }
      />
      <span className="SearchBar__action--left">
        <i className="bx bx-search-alt-2"></i>
      </span>
      {value && (
        <button
          className="SearchBar__action--right"
          aria-label="clear search"
          type="button"
          onClick={() => onClear()}
          data-testid="clear-search"
        >
          <i className="bx bx-x"></i>
        </button>
      )}
    </div>
  )
}

export default SearchInput
