import { FormEvent, useState } from 'react'
import LinkComponent from '../LinkComponent/LinkComponent'
import SearchInput from '../SearchInput/SearchInput'
import { ContentType, createSearchPageRoute } from 'shared-utilities'

const SEARCH_LINK_ID = 'search-demo-link'

const DemosSearchInput = () => {
  const [searchText, setSearchText] = useState<string>('')

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchText.length === 0) return
    document.getElementById(SEARCH_LINK_ID)?.click()
  }

  return (
    <form className="DemoSearch" onSubmit={handleSearchSubmit}>
      <SearchInput
        onInputChange={setSearchText}
        onClear={() => setSearchText('')}
        value={searchText}
      />
      <LinkComponent
        href={createSearchPageRoute({
          q: searchText,
          contentType: ContentType.DEMO,
        })}
        id={SEARCH_LINK_ID}
        openInCurrentTab={true}
        aria-label="Submit search"
      />
    </form>
  )
}

export default DemosSearchInput
